import * as direportdata from '$lib/diapi/direportdatascorius.js';
import * as direportdetails from '$lib/diapi/direportdetailsscorius.js';
import * as direports from '$lib/diapi/direports.js';
import { get } from 'svelte/store';

import {
	allReportDataForFetchedGroups,
	dashboardFocusReportSlug,
	dataIsCurrentlyLoading,
	intitialDataFetched,
	serverEnvironment
} from '../stores/dataStore.js';

import {
	availableLanguagesInDashboardThatCanBeSelected,
	availableLanguagesProgrammedInDashboard,
	currentLanguage
} from '../stores/languageStore.js';

export async function fetchAvailableReports() {
	//This function only runs at the initialization
	let isThisASeverEnvironment = get(serverEnvironment);
	//console.log("fetchAvailableReports on server: ", isThisASeverEnvironment)
	if (isThisASeverEnvironment) {
		const availableReportsPromise = await direports.getReports();
		if (availableReportsPromise.ok === true) {
			const availableReportsToBeSorted = availableReportsPromise.value;
			const availableReportsSorted = sortTheReportsBasedOndate(availableReportsToBeSorted);
			//  console.log('$availableReports form api stored:')
			//  console.log(availableReportsSorted)
			initializeAllReportDataForFetchedGroups(availableReportsSorted);
			return availableReportsSorted;
		} else {
			console.warn('No available reports received');
			console.log(availableReportsPromise.error);
		}
	} else {
		//      console.log('local version, getting api_v1_reports.json');
		const availableReportsPromise = await fetch('localData/mrt2/api_v1_reports.json');
		const availableReportsToBeSorted = await availableReportsPromise.json();
		const availableReportsSorted = sortTheReportsBasedOndate(availableReportsToBeSorted);
		//      console.log('$availableReports form api stored:')
		//      console.log(availableReportsSorted)
		initializeAllReportDataForFetchedGroups(availableReportsSorted);
		return availableReportsSorted;
	}
}

function initializeAllReportDataForFetchedGroups(availableReports) {
	//This function only runs at the initialization of the dashboard
	//add the slugs of all reports to allReportDataForFetchedGroups to store fetched data later on
	const allReportSlugs = availableReports.map((report) => report.slug);
	// console.log(allReportSlugs)
	//add a key for each report to allReportDataForFetchedGroups
	let tempObject = {};
	allReportSlugs.forEach((slug) => {
		tempObject[slug] = {};
	});
	allReportDataForFetchedGroups.set(tempObject);
	//  console.log('$allReportDataForFetchedGroups created:')
	//  console.log(get(allReportDataForFetchedGroups))
}

function sortTheReportsBasedOndate(reports) {
	const sortedReports = reports.sort((a, b) => {
		if (b.start_date === null || a.start_date === null) {
			console.warn('No start date for report defined', a.slug, b.slug);
		}
		return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
	});
	return sortedReports;
}

export async function fetchReportDetails(slug: string) {
	dataIsCurrentlyLoading.set(true);
	let isThisASeverEnvironment = get(serverEnvironment);
	//console.log("fetchReportDetails")
	if (isThisASeverEnvironment) {
		const reportDetailsPromise = direportdetails.getUserReportDetailsItem(slug);
		const reportDetails = await reportDetailsPromise;
		if (reportDetails.ok === true) {
			//console.log('Latest report details received');
			//console.log(reportDetails.value);
			const details = reportDetails.value;
			//    console.log('$latestReportDetails from api stored:')
			//    console.log(details)
			updateLocalDataStoreWithDetails(details);
		} else {
			//console.log('Error: latest report details not received');
			//console.log(reportDetails.error);
		}
	} else {
		//console.log('local version, getting api_v1_reports_details_1994-2023-09');
		const latestReportDetailsPromise = await fetch(
			'localData/mrt2/api_v1_reports_details.json'
		);
		const latestReportDetailsValue = await latestReportDetailsPromise.json();
		//    console.log('$latestReportDetails from api stored:')
		//     console.log(latestReportDetailsValue.data)
		updateLocalDataStoreWithDetails(latestReportDetailsValue.data);
	}
	dataIsCurrentlyLoading.set(false);
}

export type Filter = string[];
export async function fetchReportDataForUnitsAndSubgroups(
	slug: string[],
	units: string[] | null = null,
	subgroups: string[] | null = null
) {
	//console.log('loading data set:', get(dataIsCurrentlyLoading));

	//console.log('Get data for slugs', slug);
	//console.log('Get data for groups', units);
	//console.log('Get fata for subgoups', subgroups);

	//This function only runs at the initialization, and when the user selects a new group or subgroup
	// What data has been fetched: Access the store value
	let currentData = get(allReportDataForFetchedGroups);
	// console.log('This data is already fetched: ', currentData);
	//Now check per reportslug if the data of the group is already fetched
	let isThisASeverEnvironment = get(serverEnvironment);
	if (isThisASeverEnvironment) {
		slug.forEach(async (reportSlug) => {
			dataIsCurrentlyLoading.set(true);

			// console.log('Check if data for this report is already fetched: ', reportSlug);
			let groupsToBeRequested: string[][] = [];

			if (currentData[reportSlug]) {
				//    console.log('Data for this report is available: ', reportSlug);
				//      console.log(currentData[reportSlug])

				if (units) {
					units.forEach((unit) => {
						//    console.log('Check if data for this group is already fetched: ', unit);
						if (currentData[reportSlug][unit]) {
							//   console.log('Data for this group is already fetched: ', unit);
							//do nothing
						} else {
							//   console.log('Data for this group is not yet fetched: ', unit);
							groupsToBeRequested.push([unit]);
						}
					});
				}
				if (subgroups) {
					subgroups.forEach((subgroup) => {
						//  console.log('Check if data for this subgroup is already fetched: ', subgroup);
						if (units) {
							units.forEach((unit) => {
								let groupKey = unit + '_AND_' + subgroup;
								//    console.log('Check if data for this subgroup is already fetched: ', groupKey);
								if (currentData[reportSlug][groupKey]) {
									//      console.log('Data for this subgroup is already fetched: ', groupKey);
									//do nothing
								} else {
									//     console.log('Data for this subgroup is not yet fetched: ', groupKey);
									groupsToBeRequested.push([unit, subgroup]);
								}
							});
						}
					});
				}
			} else {
				console.warn(
					'Critical issue: Data for this report is not prepared in $allReportDataForFetchedGroups: ',
					reportSlug
				);
			}
			// console.log('These groups need to be requested: ', groupsToBeRequested);

			//console.log("fetchReportDataForUnitsAndSubgroups")

			const reportDataPromise = direportdata.getUserReportDataItems(
				[reportSlug],
				groupsToBeRequested
			);
			const reportData = await reportDataPromise;
			// console.log("RESULTS from API", reportSlug, reportData)
			reportData.forEach((result) => {
				if (result.ok === true) {
					//     console.log('reportData received for: ', result.value.report);
					//console.log(reportDetails.value);
					const data = result.value;
					//    console.log(data)
					updateLocalDataStoreWithData(data);
				} else {
					console.log('Error: No report data received');
					//console.log(reportData.error);
				}
			});
		});
		dataIsCurrentlyLoading.set(false);
		//console.log('server loading now set:', get(dataIsCurrentlyLoading));
	} else {
		// console.log('Local fetchedData.json loaded');
		const latestReportDataPromise = await fetch('localData/mrt2/fetchedData.json');
		const latestReportDataValue = await latestReportDataPromise.json();
		allReportDataForFetchedGroups.set(latestReportDataValue);
		//  console.log(get(allReportDataForFetchedGroups))
		dataIsCurrentlyLoading.set(false);
		//console.log('local loading set:', get(dataIsCurrentlyLoading));
	}

	//console.log('Function fetchReportDataForUnitsAndSubgroups finished');
}

export async function updateLocalDataStoreWithDetails(fetchedDetails) {
	// console.log("updateLocalDataStore with details")
	// console.log(fetchedDetails)

	let reportSlug = fetchedDetails.reportSlug;

	// Access the store value
	let currentData = get(allReportDataForFetchedGroups);
	// console.log(currentData);

	// Check and update the store

	if (!currentData[reportSlug]) {
		currentData[reportSlug] = {}; // Initialize if reportSlug does not exist
	}
	if (currentData[reportSlug]['details'] === undefined) {
		//    console.log('Details not in allReportDataForFetchedGroups, so add this one for: ', reportSlug);
		currentData[reportSlug]['details'] = fetchedDetails;
	} else {
		//    console.log('Details in allReportDataForFetchedGroups', reportSlug);
	}

	// Update the store with the new value
	allReportDataForFetchedGroups.set(currentData);
	intitialDataFetched.set(true);
	// console.log(get(allReportDataForFetchedGroups));
}

export async function updateLocalDataStoreWithData(fetchedData) {
	//console.log("updateLocalDataStore")
	// console.log(fetchedData)

	// Access the store value
	let currentData = get(allReportDataForFetchedGroups);
	// console.log(currentData);
	let reportSlug = fetchedData.report;

	//Explanation: the api returns group_summary including the responsrates of all groups.
	//Response rates of subgroups are provided in the results_summary and should be added to the local
	//fetcheddata object in case they do not exist.

	//check of there is a group_summary and add the missing groups to the group_summary to enable respons charts
	if (fetchedData.data.group_summary) {
		//There is a group_summary in the data, which is expected
		if(!currentData[reportSlug]['group_summary']){
			//there is no group summary in the local data object for this slug, so add the whole object because it does not change over time
			currentData[reportSlug]['group_summary']=fetchedData.data.group_summary
		} else {
			//do nothing because this group_summary object does not change. Subgroups are reported in results_summary
		}
	}

		
	if(fetchedData.data.results_summary) {
		//console.log("This call returns possible new response rate data objects that have to be added to the local group_summary object")
		//console.log(fetchedData.data.results_summary)

		if (!currentData[reportSlug]['group_summary']) {
			currentData[reportSlug]['group_summary'] = {}; // Initialize group_summary if it doesn't exist
		}
		
		let keysResultsSummary = Object.keys(fetchedData.data.results_summary);
			keysResultsSummary.forEach((groupInResultsSummary) => {
				if (!currentData[reportSlug]['group_summary'][groupInResultsSummary]) {
					//console.log('Response rate added for new group to group_summary: ', groupInResultsSummary)
					currentData[reportSlug]['group_summary'][groupInResultsSummary] =
						fetchedData.data.results_summary[groupInResultsSummary];
				} else {
					//console.log(groupInResultsSummary, 'is not added to group_summary because it is already present')
				}
			});
	}


	

	let groupsToBeAdded = Object.keys(fetchedData.data.results);
	//   console.log(groupsToBeAdded);

	// Check and update the store
	for (let group of groupsToBeAdded) {
		//  console.log(group);
		if (!currentData[reportSlug]) {
			currentData[reportSlug] = {}; // Initialize if reportSlug does not exist
		}
		if (currentData[reportSlug][group] === undefined) {
			//      console.log('Group not in allReportDataForFetchedGroups, so add this one', group);
			currentData[reportSlug][group] = fetchedData.data.results[group];
		} else {
			//    console.log('Group in allReportDataForFetchedGroups', group);
		}
	}

	// Update the store with the new value
	allReportDataForFetchedGroups.set(currentData);
	//console.log(reportSlug, get(allReportDataForFetchedGroups));
	//console.log(reportSlug, 'finished and stored in allReportDataForFetchedGroups');
	roughSizeOfObject(get(allReportDataForFetchedGroups));
}

function roughSizeOfObject(object) {
	const objectList = [];
	const stack = [object];
	let bytes = 0;

	while (stack.length) {
		const value = stack.pop();

		if (typeof value === 'boolean') {
			bytes += 4;
		} else if (typeof value === 'string') {
			bytes += value.length * 2;
		} else if (typeof value === 'number') {
			bytes += 8;
		} else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
			objectList.push(value);

			for (let i in value) {
				stack.push(value[i]);
			}
		}
	}
	//console.info('the current size is: ', Math.floor(bytes / 1000), 'KB');
	return bytes;
}
