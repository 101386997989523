<script lang="ts">
	import Home from '$lib/components/homeTab/homeTab.svelte';
	import ResultPage from '$lib/components/pages/ResultPage/ResultPage.svelte';
	import {
		defaultGroup,
		mainNavTabSelected
	} from '$lib/stores/dataStore';
	import HelpPage from './components/pages/HelpPage/HelpPage.svelte';
	import ResponsePage from './components/pages/ResponsePage/ResponsePage.svelte';

	import * as diuser from '$lib/diapi/diuser.js';
	import {
		allReportDataForFetchedGroups,
		availableReports,
		dashboardFocusReportSlug,
		dashboardFocusReportSlugForBenchmark,
		historicBenchmarkSlug,
		historicBenchmarksAvailable,
		modalDetailsVisible,
		organizationalUnitsSelected,
		serverEnvironment,
		standardAmountOfReportsToFetch,
		subGroupsSelected,
		userProfile
	} from '$lib/stores/dataStore';
	import { onMount } from 'svelte';

	import {
		fetchAvailableReports,
		fetchReportDataForUnitsAndSubgroups,
		fetchReportDetails,
	} from './utilities/getDataHelperFunctions.js';

	import { groups, subgroups } from '$lib/stores/dataStore';
	import type { OptionDisplayItem, OptionDisplayTreeItem } from './components/pages/components/TableControls/common/DisplayItem.js';
	import type { GroupsObject, SubgroupsObject } from './components/pages/components/TableControls/common/Group.js';
	import Modal from './components/resultsTab/modalGrid.svelte';
	import { allTranslations, availableLanguagesInDashboardThatCanBeSelected, availableLanguagesProgrammedInDashboard, currentLanguage } from './stores/languageStore.js';
	
	let showModal = false;

	let previousModalState = $modalDetailsVisible.visible;
	let previousDashboardState = false;

	// tracck store changes

	// $: $loggedIn, run('loggedIn', $loggedIn);
	// $: $serverEnvironment, run('serverEnvironment', $serverEnvironment);
	// $: $mainNavTabSelected, run('mainNavTabSelected', $mainNavTabSelected);
	// $: $dataIsCurrentlyLoading, run('dataIsCurrentlyLoading', $dataIsCurrentlyLoading);
	// $: $userProfile, run('userProfile', $userProfile);
	// $: $availableReports, run('availableReports', $availableReports);
	// $: $standardAmountOfReportsToFetch,
	// 	run('standardAmountOfReportsToFetch', $standardAmountOfReportsToFetch);
	// $: $intitialDataFetched, run('intitialDataFetched', $intitialDataFetched);
	// $: $allReportDataForFetchedGroups,
	// 	run('allReportDataForFetchedGroups', $allReportDataForFetchedGroups);
	// $: $dashboardFocusReportSlug, run('dashboardFocusReportSlug', $dashboardFocusReportSlug);
	// $: $dashboardFocusReportSlugForBenchmark,
	// 	run('dashboardFocusReportSlugForBenchmark', $dashboardFocusReportSlugForBenchmark);
	// $: $organizationalUnitsSelected, run('organizationalUnitsSelected', $organizationalUnitsSelected);
	// $: $heatmapMode, run('heatmapMode', $heatmapMode);
	// $: $historicBenchmarkMode, run('historicBenchmarkMode', $historicBenchmarkMode);
	// $: $historicBenchmarkSlug, run('historicBenchmarkSlug', $historicBenchmarkSlug);
	// $: $modalDetailsVisible, run('modalDetailsVisible', $modalDetailsVisible);
	// $: $groups, run('groups', $groups);

	const run = (name: string, value: any) => console.log(`    changed ${name}`, value);

	//

	$: {
		// Handle modal visibility changes
		if ($modalDetailsVisible.visible !== previousModalState) {
			previousModalState = $modalDetailsVisible.visible;
			if ($modalDetailsVisible.visible) {
				openModal();
			} else {
				closeModal();
			}
		}
	}

	$: {
		if ($organizationalUnitsSelected) {
			//console.log('Start update because there is a change in organizationalUnitsSelected');
			getDashboardDataAfterSelection();
		}
	}
	$: {
		if ($subGroupsSelected) {
			//console.log('Start update because there is a change in subGroupsSelected');
			getDashboardDataAfterSelection();
		}
	}

	function openModal() {
		showModal = true;
	}

	function closeModal() {
		showModal = false;
		modalDetailsVisible.set({
			visible: false,
			title: '',
			question: '',
			team: ''
		});
	}

	async function getUserData() {
		if (!$serverEnvironment) {
			const response = await fetch('localData/mrt2/apiUser.json');
			const data = await response.json();
			userProfile.set(data);
			//console.log(data.user.username, ' has logged in');
		} else {
			const user = await diuser.getUserProfile();
			if (user.ok === true) {
				//console.log('User has logged in:', user.value.user.username);
				userProfile.set(user.value);
			} else {
				//console.log('User data not yet available, transfer to login page?');
				// console.log(user);
				// auth.login();
			}
		}
	}

	onMount(async () => {
		getUserData();
		getInitialDashboardData();
	});

	$: {
		if($allReportDataForFetchedGroups && $dashboardFocusReportSlug && $allReportDataForFetchedGroups[$dashboardFocusReportSlug].details && $allReportDataForFetchedGroups[$dashboardFocusReportSlug].group_summary) {
			if(!$groups) {
				groups.set(createGroups($allReportDataForFetchedGroups[$dashboardFocusReportSlug].details, $allReportDataForFetchedGroups[$dashboardFocusReportSlug].group_summary));
			}

			if(!$subgroups) {
				subgroups.set(createSubGroups($allReportDataForFetchedGroups[$dashboardFocusReportSlug].details.subgroups));
			}
		}
	}

	$: {
		if($allReportDataForFetchedGroups && $dashboardFocusReportSlug && $allReportDataForFetchedGroups[$dashboardFocusReportSlug]['details'] && $currentLanguage.code === undefined){
				let dashboardFocusReportDetailsForOrganization = $allReportDataForFetchedGroups[$dashboardFocusReportSlug]['details'];

			
			
				// console.log(
				//     'Available languages in data: dashboardFocusReportDetailsForOrganization.data.context.i18n.availableLangs'
				// );
				// console.log(dashboardFocusReportDetailsForOrganization.context.i18n);
				
				let defaultLanguageKey = dashboardFocusReportDetailsForOrganization.context.i18n.defaultLang;
				const filteredLanguages = availableLanguagesProgrammedInDashboard.filter((language) =>
					dashboardFocusReportDetailsForOrganization.context.i18n.availableLangs.includes(language.code)
				);
				const defaultLanguage = availableLanguagesProgrammedInDashboard.filter(
					(language) => defaultLanguageKey === language.code
				);


				availableLanguagesInDashboardThatCanBeSelected.set(filteredLanguages);
				currentLanguage.set(defaultLanguage[0]);

				// console.log(get(availableLanguagesInDashboardThatCanBeSelected))
				//  console.log(get(currentLanguage))
			
			}
		
	}

	const createSubGroups = (subgroupsData: any): SubgroupsObject => {
		let _subgroups: SubgroupsObject = {};
		let dropdownsToMake = Object.keys(subgroupsData);

		dropdownsToMake.forEach((dropdownKey) => {
			let groupObject = subgroupsData[dropdownKey].children;
			let currentLevel = 0;

			_subgroups[dropdownKey] = {
				displayName: subgroupsData[dropdownKey].name,
				options: []
			};

			for (let groupId in groupObject) {
				processGroup(groupObject[groupId], groupObject[groupId]['slug'], dropdownKey);
			}

			function processGroup(group, groupId, dropdownKey) {
				let groupIdx: number = Object.keys(_subgroups).indexOf(dropdownKey) + 1;

				let option: OptionDisplayItem = {
					value: groupId,
					label: group.name[$currentLanguage.code],
					originalLabel: group.name,
					parent: group.parentId,
					className: `subgroup-${(groupIdx)}`,
					selectable: true
				};
				_subgroups[dropdownKey].options.push(option);

				// Recursively process children PROBABLY NOT NEEDED FOR SUBGROUPS
				if (group.children) {
					currentLevel++; // Increment the level before processing children
					let children = group.children;
					for (let childId in children) {
						processGroup(children[childId], children[childId]['slug'], dropdownKey);
					}
					currentLevel--; // Decrement the level after processing children
				}
			}
		});

		dropdownsToMake.forEach((k) => $subGroupsSelected[k] = []);

		return _subgroups;
	}

	const createGroups = (groupsData, groupsSummaryData): GroupsObject => {
		let groupKeys = Object.keys(groupsData.groups);
		let _groups: GroupsObject = {};

		groupKeys.forEach((groupKey) => {
			let groupObject = groupsData.groups[groupKey].children;
			let currentLevel = 0;
			_groups[groupKey] = {
				displayName: groupsData.groups[groupKey].name,
				options: []
			};

			for (let groupId in groupObject) {
				processGroup(groupObject[groupId], groupObject[groupId].slug, groupKey);
			}

			function processGroup(this: any, group, groupId, key) {
				let groupIdx: number = Object.keys(_groups).indexOf(key) + 1;

				let option: OptionDisplayTreeItem = {
					value: groupId,
					label: group.name[$currentLanguage.code],
					dropdownParent: key,
					originalLabel: group.name,
					parent: group.parentId,
					// Set default values that could be changed based on conditions
					labelPostfix: '', // Default value, might change below
					disabled: false, // Default value, might change below
					className: `group-${(groupIdx)}`,
					depth: groupId.split('.').length - 1,
					selectable: true,
					restricted: group.restricted
				};

				const groupData = groupsSummaryData?.[groupId];

				if (groupData) {
					if (groupData?.data_available === true) {
						// If data is available, the default option values are already correct
					} else {
						// If data is not available, modify the labelPostfix and set disabled to true
						option.labelPostfix = `(${allTranslations['select']['geenData'][$currentLanguage.code]})`;
						option.disabled = true;
						option.selectable = false;
					}
				} else {
					// If groupData is undefined, modify the labelPostfix and keep disabled as false
					option.labelPostfix = ` (${allTranslations['select']['onbekendData'][$currentLanguage.code]}) `;
					option.selectable = false;
				}
				option.label = option.label + option.labelPostfix;

				_groups[key]['options'].push(option);

				// Recursively process children
				if (group.children) {
					currentLevel++; // Increment the level before processing children
					let children = group.children;
					for (let childId in children) {
						processGroup(children[childId], children[childId]['slug'], key);
					}

					currentLevel--; // Decrement the level after processing children
				}
			}
		});

		groupKeys.forEach((k) => $organizationalUnitsSelected[k] = []);

		$defaultGroup = groupsData.context.defaultGroup.slug.toLowerCase();
		
		if(groupKeys.length > 0 && _groups[$defaultGroup].options.length > 0) {
			const _defaultSelection = _groups[$defaultGroup].options[0];
			_defaultSelection.selectable = false;
			$organizationalUnitsSelected[$defaultGroup] = [_defaultSelection];
		}

		groupKeys.forEach((k) => _groups[k].options = _groups[k].options.filter((o) => o.restricted === undefined || o.restricted === false));
		return _groups;
	};

	async function getInitialDashboardData() {
		//console.log('%c###Initial Data Request###', 'color: purple;');

		//Get all available reports and set them in the store
		let availableReportsSorted = await fetchAvailableReports();
		//console.log('availableReportsSorted in getDataHelperFunctions.ts', availableReportsSorted);
		availableReports.set(availableReportsSorted);

		//Define the latest report as the one with the highest index after sorting
		let slugOnWhichTheDashboardShouldFocus = $availableReports[0];
		let slugOnWhichTheDashboardShouldBenchmark =
			$availableReports.length > 1 ? $availableReports[1] : $availableReports[0];

		//This boolean is used to check if there are any historic benchmarks available, if not badges are not shown
		if($availableReports.length === 1) {
			console.log('No historic benchmarks available, so historicBenchmarksAvailable set false')
			$historicBenchmarksAvailable=false;
		}

		//console.log('SET: $dashboardFocusReportSlug', slugOnWhichTheDashboardShouldFocus.slug);
		dashboardFocusReportSlug.set(slugOnWhichTheDashboardShouldFocus.slug);
		// console.log(
		// 	'SET: $dashboardFocusReportSlugForBenchmark',
		// 	slugOnWhichTheDashboardShouldBenchmark.slug
		// );
		dashboardFocusReportSlugForBenchmark.set(slugOnWhichTheDashboardShouldBenchmark.slug);
		//set the historic benchmark slug to the same as the latest report for the tables later
		$historicBenchmarkSlug = $dashboardFocusReportSlugForBenchmark;

		//fetch details and first data for the latest report
		fetchReportDetails($dashboardFocusReportSlug);
		//console.log('Data is now present:', $allReportDataForFetchedGroups );
		let arrayWithSlugsToFetchInitially = [];
		//get .slug from the first $standardAmountOfReportsToFetch reports
		for (let i = 0; i < $standardAmountOfReportsToFetch; i++) {
			if (i < $availableReports.length) {
				// Check if the index exists in the array
				arrayWithSlugsToFetchInitially.push($availableReports[i].slug);
			} else {
				break; // Exit the loop if we've reached the end of the available reports
			}
		}

		await fetchReportDataForUnitsAndSubgroups(arrayWithSlugsToFetchInitially, null, null);
		//when is is finished set the languages for the dashboard that gets the data from the main data object
		//setLanguagesDashboard();
		// Initialize dropdowns for groups which triggers the download of first data
		//initializeDropdownSelectionObject('groups', $organizationalUnitsSelected);
		// Initialize dropdowns for subgroups
		//initializeDropdownSelectionObject('subgroups', $subGroupsSelected);

		//console.log('%c###Initial Data Request Finished###', 'color: purple;');
		//console.log('Data is now present:', $allReportDataForFetchedGroups);
	}

	function initializeDropdownSelectionObject(reportDetailsKey, selectionStore) {
		// console.log('$allReportDataForFetchedGroups', $allReportDataForFetchedGroups);
		// console.log('$dashboardFocusReportSlug', $dashboardFocusReportSlug);

		let dropdownsToMake = Object.keys(
			$allReportDataForFetchedGroups[$dashboardFocusReportSlug]['details'][reportDetailsKey]
		);

		dropdownsToMake.forEach((dropdownKey) => {
			if (!selectionStore[dropdownKey]) {
				selectionStore[dropdownKey] = [];
			}
		});
	}

	async function getDashboardDataAfterSelection() {
		//console.log('%c###New Data Request###', 'color: purple;');
		if (
			$dashboardFocusReportSlug === null ||
			$dashboardFocusReportSlug === undefined ||
			$dashboardFocusReportSlug === ''
		) {
			//console.log('No report selected yet. No data requested.');
			return;
		}
		//console.log('$dashboardFocusReportSlug:', $dashboardFocusReportSlug);
		//console.log('$dashboardFocusReportSlugForBenchmark:', $dashboardFocusReportSlugForBenchmark);

		//organizationalUnitsSelected contains objects with .value
		//we need to make an array with only the values

		let arrayWithGroupKeys = [];
		let keysGroups = Object.keys($organizationalUnitsSelected);
		keysGroups.forEach((key) => {
			$organizationalUnitsSelected[key].forEach((group) => {
				arrayWithGroupKeys.push(group.value);
			});
		});
		if (arrayWithGroupKeys.length === 0) {
			arrayWithGroupKeys = null;
		}

		let arrayWithSubGroupKeys = [];
		let keysSubgroups = Object.keys($subGroupsSelected);
		keysSubgroups.forEach((key) => {
			$subGroupsSelected[key].forEach((subgoup) => {
				arrayWithSubGroupKeys.push(subgoup.value);
			});
		});
		if (arrayWithSubGroupKeys.length === 0) {
			arrayWithSubGroupKeys = null;
		}
		let arrayWithSlugsToFetchInitially = [];
		// Determine the actual number of reports to fetch to avoid exceeding the available reports
		let actualNumberOfReportsToFetch = Math.min(
			$standardAmountOfReportsToFetch,
			$availableReports.length
		);

		for (let i = 0; i < actualNumberOfReportsToFetch; i++) {
			arrayWithSlugsToFetchInitially.push($availableReports[i].slug);
		}

		fetchReportDataForUnitsAndSubgroups(
			arrayWithSlugsToFetchInitially,
			arrayWithGroupKeys,
			arrayWithSubGroupKeys
		);
	}
</script>

<!-- svelte-ignore missing-declaration -->
{#if $mainNavTabSelected === 'tab1'}
	<Home />
{:else if $mainNavTabSelected === 'tab2'}
	<ResultPage />
{:else if $mainNavTabSelected === 'tab3'}
	<ResponsePage />
{:else if $mainNavTabSelected === 'tab4'}
	<HelpPage />
{/if}

<Modal
	bind:isOpen={showModal}
	close={closeModal}
	title={$modalDetailsVisible.title}
	question={$modalDetailsVisible.question}
	team={$modalDetailsVisible.team}
/>

<style>
	.hidden {
		display: none;
	}
</style>
