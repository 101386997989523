<!-- Modal.svelte -->
<script lang="ts">
	import { get } from 'svelte/store';
	import { onMount, tick } from 'svelte';

	export let isOpen = false;
	export let question = '';
	export let team = '';
	export let close = () => {};


	import {
		availableReports,
		allReportDataForFetchedGroups,
		dashboardFocusReportSlug,
		dashboardFocusReportSlugForBenchmark,
		organizationalUnitsSelected,
		subGroupsSelected,
		standardAmountOfReportsToFetch,
		agGridLicense
	} from '../../stores/dataStore.ts';
	import { allTranslations, currentLanguage } from '../../stores/languageStore.js';
	import {
		getQuestionData,
		getDataForSpecificGroup,
		scaleOrginalValueToDesiredScale,
		getAllKeysForGroupAndSubgroups,
		getGroupNameForKey,
		getDisplayReportName
	} from '../../utilities/formatDataHelperFunctions.js';

	import { AgCharts } from 'ag-charts-enterprise';
	import type { AgCartesianSeriesTooltipRendererParams } from 'ag-charts-enterprise';
	import IconButton from '../pages/components/TableControls/common/IconButton/IconButton.svelte';

	AgCharts.setLicenseKey(agGridLicense);

	let modalTitle = 'x';
	let modalQuestion = 'q';
	let modalResponseType = {};
	let modalAnswerOptions = [];

	function renderer(params: AgCartesianSeriesTooltipRendererParams) {
		if (params.datum && typeof params.datum[params.yKey] === 'number') {
			return {
				content: params.datum[params.yKey].toFixed(1)
			};
		}
		return { content: params.value };
	}
	function rendererBarChart(params: AgCartesianSeriesTooltipRendererParams) {
		if (params.datum && typeof params.datum[params.yKey] === 'number') {
			return {
				content: params.datum[params.yKey].toFixed(1)+"%"
			};
		}
		return { content: params.value };
	}

	const optionsBar: AgChartOptions = {
		container: '',
		title: {
			text: 'Verdeling antwoorden'
		},
		contextMenu: {
			enabled: false
		},
		theme: {
			overrides: {
				common: {
					title: {
						textAlign: 'left',
						color: '#495057',
						fontFamily: 'Poppins, Roboto, "Helvetica Neue", sans-serif',
						fontWeight: '500'
					}
				}
			}
		},
		axes: [
				{
				type: "number",
				position: "left",
				label: {
					formatter: (params) => `${params.value}%`,
					autoRotate:false
				},
				crosshair: {
					enabled: false,
				},
				},
				{
				type: "category",
				position: "bottom",
				label: {
					autoRotate:false
				},
				crosshair: {
					enabled: false,
				},
				},
			],
			

		data: [],
		series: []
	};

	let thisIsLegendClick: boolean = false;

	const optionsLine: AgChartOptions = {
		container: '',
		title: {
			text: allTranslations['modal']['titleChart1'][$currentLanguage.code]
		},
		contextMenu: {
			enabled: false
		},
		legend: {
			position: 'left',
			preventHidingAll: true,
			listeners: {
				legendItemClick: (event) => {
					
					thisIsLegendClick = true;

					if(optionsLine.legend.position==='left'){
						console.log('barchart should now be hiding or showing: ', event.itemId)
						console.log(optionsBar)
						//search array for item with value of event.itemId
						let valueToSearchConvertedInNumber = parseInt(event.itemId, 10); // Use radix 10 for decimal
						// Check if the conversion was successful to avoid searching with NaN
						if (!isNaN(valueToSearchConvertedInNumber)) {
							let item = optionsBar.data.find(item => item.value === valueToSearchConvertedInNumber);
							// Check if an item was found to avoid errors when trying to access properties on undefined
							if (item !== undefined) {
								console.log(item);
								//change the visibility of the item, is not working
								//item.visible = !item.visible;
							} else {
								console.log("Item not found for value:", valueToSearchConvertedInNumber);
							}
						} else {
							console.log("Invalid item ID, could not convert to number:", event.itemId);
						}
					
					
					
						//AgCharts.update(barchart, optionsBar);
					}

				}
			}
		},

		listeners: {
			seriesNodeClick: (event) => {
				//console.log('seriesNodeClick', event);
				//updateBarChartAfterClick(event);
				let report = event.datum['slug'];
				let team = event.yKey;
				updateBarChartAfterClick(team, report);
			}
		

		},

		// subtitle: {
		// 	text: 'per antwoord optie'
		// },
		theme: {
			overrides: {
				common: {
					title: {
						textAlign: 'left',
						color: '#495057',
						fontFamily: 'Poppins, Roboto, "Helvetica Neue", sans-serif',
						fontWeight: '500'
					}
				},
				line: {
					series: {
						highlightStyle: {
							series: {
								dimOpacity: 0.2,
								strokeWidth: 4
							}
						}
					}
				},
				area: {
					series: {
						highlightStyle: {
							series: {
								dimOpacity: 0.2,
								strokeWidth: 4
							}
						}
					}
				}
			}
		},
		axes: [
				{
				type: "number",
				position: "left",
				label: {
					formatter: (params) => `${params.value}`,
					autoRotate:false
				},
				crosshair: {
					enabled: false,
				},
				},
				{
				type: "category",
				position: "bottom",
				crosshair: {
					enabled: false,
				},
				},
			],
		data: [],
		series: []
	};

	let barchart;
	let linechart;
	let aCellWithDataWasClicked: boolean = true;


	$: if (isOpen) {
		//console.log('GO', team);
		let isChartCreated = false;
		if (team === undefined || team === '' || team === null) {
			aCellWithDataWasClicked = false;
			//console.log('Do not collect data for this modal', team);
		} else {
			aCellWithDataWasClicked = true;
		}
		let questionID = question.replace('rowForItem_', '');
		let teamID = team;
		let questionObject = getQuestionData(questionID);
		//console.log(questionObject);
		//console.log(questionObject['text']);
		let localLanguage = get(currentLanguage);
		//console.log(localLanguage['code']);
		let setLanguageCode = localLanguage['code'];

		modalTitle = `${questionObject['text'][setLanguageCode]}`;
		modalQuestion = questionObject['text'][setLanguageCode];
		modalResponseType = questionObject['responseType'];

		optionsBar.title.text =
			allTranslations['modal']['titleChart2'][$currentLanguage.code] +
			getGroupNameForKey(teamID) +
			allTranslations['modal']['titleChart3'][$currentLanguage.code] +
			getDisplayReportName($dashboardFocusReportSlug);

		modalAnswerOptions = Object.values(modalResponseType.options).sort(
			(a, b) => a.orderBy - b.orderBy
		);
		//console.log(modalAnswerOptions);

		if (aCellWithDataWasClicked) {
			//get data for the modal
			//console.log('aCellWithDataWasClicked', aCellWithDataWasClicked);
			let arrayWithSlugsToFetch = [];
			let actualNumberOfReportsToFetch = Math.min($standardAmountOfReportsToFetch, $availableReports.length);
			for (let i = 0; i < actualNumberOfReportsToFetch; i++) {
				arrayWithSlugsToFetch.push($availableReports[i].slug);
			}
			//inverse order arrayWithSlugsToFetch
			arrayWithSlugsToFetch.reverse();
			let dataObject = getDataForSpecificGroup(arrayWithSlugsToFetch, teamID, questionID);
			//console.log(dataObject);

			let detailsForModal = $allReportDataForFetchedGroups[$dashboardFocusReportSlug]['details'];
			let detailsForModalBenchmark =
				$allReportDataForFetchedGroups[$dashboardFocusReportSlugForBenchmark]['details'];

			//console.log(detailsForModal);

			let totalScoreCountBar = 0;
			let barchartData = [];
			const reportData = dataObject?.[$dashboardFocusReportSlug]?.['count'] || {};

			// Calculate totalScoreCountBar safely
			let keys = Object.keys(reportData);
			keys.forEach((key) => {
				// Ensure the value is a number before adding it to totalScoreCountBar
				let countValue = Number(reportData[key]) || 0;
				totalScoreCountBar += countValue;
			});

			modalAnswerOptions.forEach((answerOption) => {

				let score =
					dataObject?.[$dashboardFocusReportSlug]?.['count']?.[answerOption.value.toString()] ?? 0;
				let percentage = totalScoreCountBar > 0 ? (score / totalScoreCountBar) * 100 : 0;

				barchartData.push({
					answerOption: answerOption.text[$currentLanguage.code],
					value: answerOption.value,
					group: percentage
				});
			});
			optionsBar.series = [
				{
					type: 'bar',
					direction: 'vertical',
					tooltip: { renderer: rendererBarChart },
					xKey: 'answerOption',
					yKey: 'group',
					yName: getGroupNameForKey(team)
					
				}
			];
			//sort the order of the barchartData based on value
			barchartData.sort((a, b) => a.value - b.value);



			console.log(barchartData);
			optionsBar.data = barchartData;

			//For linechart we should check the response type first

			let responseType = dataObject[$dashboardFocusReportSlug]?.['responseType'];

			if (!responseType) {
				//stop this if
				//console.log('no response type available, but launch Modal');
				optionsBar.data = [];
				optionsBar.series = [];
				optionsLine.data = [];
				optionsLine.series = [];
			} else if (responseType && responseType.includes('LIKERT')) {
				let allGroupsInTable = getAllKeysForGroupAndSubgroups(
					$organizationalUnitsSelected,
					$subGroupsSelected
				);
				//	console.log('allGroupsInTable', allGroupsInTable);

				let lineChartData = [];
				let lineChartSeries = [];

				let slugsReports = Object.keys(dataObject);

				slugsReports.forEach((slug) => {
					// console.log($availableReports)
					// console.log($allReportDataForFetchedGroups?.[slug])
					// let slugDisplayname=$allReportDataForFetchedGroups?.[slug]?.['details']?.['context']?.['measurement']?.['peroid']?.['name'][currentLanguage.code] ?? slug;
					// console.log(slugDisplayname)
					let slugDisplayname = slug;

					let dataObjectForChart = {
						report: getDisplayReportName(slugDisplayname),
						slug: slugDisplayname
					};
					allGroupsInTable.forEach((group) => {
						let dataObjectTeam = getDataForSpecificGroup(arrayWithSlugsToFetch, group, questionID);
						//console.log(dataObjectTeam);
						if (dataObjectTeam[slug] !== null) {
							let originalScore = dataObjectTeam[slug]['mean'];
							let scaledScore = scaleOrginalValueToDesiredScale(questionID, originalScore);
							let groupKeyForChart = group.replace(/\./g, '_*replacedot*_');
							dataObjectForChart[groupKeyForChart] = scaledScore;
						} else {
							let groupKeyForChart = group.replace(/\./g, '_*replacedot*_');
							dataObjectForChart[groupKeyForChart] = null;
						}
					});
					//console.log(dataObjectForChart);
					lineChartData.push(dataObjectForChart);
				});
				allGroupsInTable.forEach((group) => {
					let groupKeyForChart = group.replace(/\./g, '_*replacedot*_');
					lineChartSeries.push({
						type: 'line',
						tooltip: { renderer: renderer },
						xKey: 'report',
						yKey: groupKeyForChart,
						yName: getGroupNameForKey(group),
						nodeClickRange: 'nearest',
						connectMissingData: false,
						marker: {
							size: 12
						}
					});
				});

				//console.log(lineChartData);
				optionsLine.title.text = modalTitle;
				optionsLine.data = lineChartData;
				optionsLine.series = lineChartSeries;
				optionsLine.legend.position = 'bottom';
				optionsLine.legend.maxHeight= 50,
				optionsLine.legend.maxWidth= 700,

				optionsBar.axes= [
			
					
							{
							type: "number",
							position: "left",
							label: {
								formatter: (params) => `${params.value}%`,
								autoRotate : false

							},
							crosshair: {
								enabled: false,
							},
							},
							{
							type: "category",
							position: "bottom",
							label: {
								//Hier zit de rotate van de vertikale bar
								autoRotate : false,
								avoidCollisions: false
							},
							crosshair: {
								enabled: false,
							},
							},
						]

				
			} else if (responseType.includes('CHOICE')) {
				let areachartData = [];
				let areachartSeries = [];
				let slugsReports = Object.keys(dataObject);
				let groupKeyForChart = teamID.replace(/\./g, '_*replacedot*_');
				optionsLine.title.text = modalTitle;
				optionsLine.legend.position = 'left';
				optionsLine.legend.maxHeight= 250,
				optionsLine.legend.maxWidth= 300,

				optionsBar.series[0].direction = 'horizontal';
				optionsBar.axes= [
			
							{
							type: "category",
							position: "left",
							crosshair: {
								enabled: false,
							},
							label: {
								autoRotate : false
							},
							},
							{
							type: "number",
							position: "bottom",
							label: {
								formatter: (params) => `${params.value}%`,
								autoRotate : false
							},
							crosshair: {
								enabled: false,
							},
							}
						],

		
				
				// console.log(dataObject)
				// console.log(modalAnswerOptions)

				modalAnswerOptions.forEach((answerOption) => {
					areachartSeries.push({
						type: 'line',
						xKey: 'report',
						tooltip: { renderer: renderer },
						//xName: 'headerName',
						yKey: JSON.stringify(answerOption.value),
						yName: answerOption.text[$currentLanguage.code]
					});
				});
				slugsReports.forEach((slug) => {
					let objectForChart = { report: getDisplayReportName(slug), slug: slug };
					let totalScoreCount = 0;
					const countData = dataObject[slug]?.['count'] || {};
					// Calculate totalScoreCount safely
					Object.keys(countData).forEach((key) => {
						// Ensure the value is a number before adding it to totalScoreCount
						let countValue = Number(countData[key]) || 0;
						totalScoreCount += countValue;
					});
					modalAnswerOptions.forEach((answerOption) => {
						let score = dataObject?.[slug]?.['count']?.[JSON.stringify(answerOption.value)] ?? 0;
						let percentage = totalScoreCount > 0 ? (score / totalScoreCount) * 100 : 0;

						objectForChart[JSON.stringify(answerOption.value)] = percentage;
					});

					areachartData.push(objectForChart);
				});

				//console.log(areachartData, areachartSeries);
				optionsLine.data = areachartData;
				optionsLine.series = areachartSeries;
			} else {
				//console.log('no linechart for this response type');
				optionsLine.data = [];
				optionsLine.series = [];
			}
			// Delay the chart setup to ensure the DOM element is available
			initializeCharts();
		} else {
			//console.log('no data available, but launch Modal');
		}
	}

	async function initializeCharts() {
		//console.log('Modal is open, setting up chart');

		// Wait for any state changes to be applied to the DOM
		await tick();

		// Now the DOM elements should be available
		getBarChartContainer();
		getLineChartContainer();
	}

	function getBarChartContainer() {
		let containerChart = document.getElementById('barchartModal');
		if (containerChart) {
			//console.log('Found barchart container', containerChart);
			optionsBar.container = containerChart;
			barchart = AgCharts.create(optionsBar);
		} else {
			console.error('barchart container not found');
		}
	}
	function getLineChartContainer() {
		let containerChart = document.getElementById('linechartModal');
		if (containerChart) {
			//	console.log('Found linechart container', containerChart);
			optionsLine.container = containerChart;
			linechart = AgCharts.create(optionsLine);
		} else {
			console.error('barchart container not found');
		}
	}

	function updateBarChartAfterClick(teamMentionedInEvent, reportslug) {
		let questionID = question.replace('rowForItem_', '');
		let teamId = teamMentionedInEvent.replace(/_\*replacedot\*_/g, '.');
		let chartOrientation: string ='';

		// Check if 'horizontal' is explicitly set or not available
		if (optionsBar.series && optionsBar.series.length > 0 && optionsBar.series[0].direction !== 'horizontal') {
			// If 'horizontal' is not set, use the current direction setting
			chartOrientation = 'vertical';
			console.log('Chart orientation is set to:', chartOrientation);
		} else {
			
			chartOrientation = 'horizontal'; 
			// event has the wrong teamId so we reset it to the original prop id
			teamId=team;
		}

	

		 let questionObject = getQuestionData(questionID);
		 //console.log(questionObject);

		let localLanguage = get(currentLanguage);
		let setLanguageCode = localLanguage['code'];

		optionsBar.title.text =
			allTranslations['modal']['titleChart2'][$currentLanguage.code] +
			getGroupNameForKey(teamId) +
			allTranslations['modal']['titleChart3'][$currentLanguage.code] +
			getDisplayReportName(reportslug);

		//console.log(modalAnswerOptions);

		let dataObject = getDataForSpecificGroup([reportslug], teamId, questionID);
		//	console.log(dataObject);

		if (dataObject[reportslug] !== null) {
			let barchartData = [];
			let barchartSeries = [];
			let totalScoreCountBar = 0;
			let keys = Object.keys(dataObject[reportslug]['count']);
			keys.forEach((key) => {
				totalScoreCountBar = totalScoreCountBar + dataObject[reportslug]['count'][key];
			});
			modalAnswerOptions.forEach((answerOption) => {
				let score = dataObject?.[reportslug]?.['count']?.[answerOption.value.toString()] ?? 0;
				let percentage = totalScoreCountBar > 0 ? (score / totalScoreCountBar) * 100 : 0;

				barchartData.push({
					answerOption: answerOption.text[$currentLanguage.code],
					value: answerOption.value,
					group: percentage
				});
			});
			barchartData.sort((a, b) => a.value - b.value);
			barchartSeries = [
				{
					type: 'bar',
					direction: chartOrientation,
					tooltip: { renderer: rendererBarChart },
					xKey: 'answerOption',
					yKey: 'group',
					yName: getGroupNameForKey(teamId)
				}
			];
			optionsBar.data = barchartData;
			optionsBar.series = barchartSeries;

			// optionsBar.axes.label.autoRotate = false;
			// optionsBar.axes[1].label.autoRotate = false;

			AgCharts.update(barchart, optionsBar);
		} else {
			optionsBar.data = [];
			optionsBar.series = [];
		}
	}
</script>

{#if isOpen}
	<div class="modal-background" on:click={close}>
		<div class="modal-content" on:click|stopPropagation>
			<h2 class='mb-2'>{allTranslations['modal']['titleChart1'][$currentLanguage.code]}</h2>
			<!-- <IconButton title={allTranslations['modal']['close'][$currentLanguage.code]} classes="fa-regular fa-xmark" onClick={close} ></IconButton> -->

			<hr />
			<br />

			{#if aCellWithDataWasClicked}
				<div id="linechartModal" />
				<div id="barchartModal" />
			{/if}

			<slot />
		</div>
	</div>
{/if}

<style>
	.modal-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9998;
	}

	.modal-content {
		background-color: #fff;
		padding: 20px;
		border-radius: 8px;
		width: 80%;
		max-width: 70vw;
		z-index: 9999;
	}

	[data-ag-charts] {
		overflow: hidden;
	}

	/* Center charts with explicit width and heights */
	.ag-chart-wrapper {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
	}

	button:not(#myGrid button, #myChart button, button[class*='ag-'], .ag-chart-context-menu button) {
		--background-color: transparent;
		--text-color: #212529;
		--color-border-primary: #d0d5dd;
		--hover-background-color: rgba(0, 0, 0, 0.1);

		appearance: none;
		border: 1px solid var(--color-border-primary);
		border-radius: 6px;
		height: 36px;
		color: var(--text-color);
		background-color: var(--background-color);
		cursor: pointer;
		display: inline-block;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.01em;
		padding: 0.375em 1em 0.5em;
		white-space: nowrap;
		margin-right: 6px;
		margin-bottom: 8px;
		transition: background-color 0.25s ease-in-out;
	}

	button:not(
			#myGrid button,
			#myChart button,
			button[class*='ag-'],
			.ag-chart-context-menu button
		):hover {
		background-color: var(--hover-background-color);
	}
</style>
