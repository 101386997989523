import type { GroupsObject, SubgroupsObject } from '$lib/components/pages/components/TableControls/common/Group';
import { writable, type Writable } from 'svelte/store';

export let loggedIn = writable(false);
export let serverEnvironment = writable(true);

//Navigation
export const mainNavTabSelected = writable('tab1');
export const dataIsCurrentlyLoading = writable(false);

//Global Data Storage
export const userProfile = writable();
export const availableReports = writable();
export const standardAmountOfReportsToFetch = writable(6);
export const intitialDataFetched = writable(false);
export const allReportDataForFetchedGroups = writable();
export const dashboardFocusReportSlug = writable('');
export const dashboardFocusReportSlugForBenchmark = writable('');
export const organizationalUnitsSelected = writable([]);
export const subGroupsSelected = writable([]);
export const defaultGroup = writable('');

//
export const groups: Writable<GroupsObject> = writable();
export const subgroups: Writable<SubgroupsObject> = writable();

//Global Grid Settings
export const heatmapMode = writable(false);
export const historicBenchmarkMode = writable(true);
export const historicBenchmarksAvailable = writable(true);
export const historicBenchmarkSlug = writable('to be defined');

export const exportTableOptions: Writable<{[key: string]: {[key: string]: { exportExcel(): void, exportCSV(): void, minimize(): void, maximize(): void}}}>  = writable({ "tab2": {}, "tab3": {}});

export const agGridLicense =
	'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052628}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Data_Ink_BV}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Scorius_Dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Scorius_Dashboard}_need_to_be_licensed___{Scorius_Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{27_December_2024}____[v3]_[0102]_MTczNTI1NzYwMDAwMA==da2cf127f49ace050f8e0ece80d43644';

export const modalDetailsVisible = writable({
	visible: false,
	title: '',
	question: '',
	team: ''
});